/**
 * @see helpers/global
 */
import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

// POST products
const fetchProducts = postData => axios.post(
    `${API_URL}/customer/articles/overview`, postData, getApiHeaders()
);
const fetchProductRepairs = postData => axios.post(
    `${API_URL}/customer/articles/product/repairs/overview`, postData, getApiHeaders()
);
const fetchProductCleanings = postData => axios.post(
    `${API_URL}/customer/articles/product/cleanings/overview`, postData, getApiHeaders()
);
const fetchProductInspections = postData => axios.post(
    `${API_URL}/customer/articles/product/inspections/overview`, postData, getApiHeaders()
);
const findQrCode = postData => {
    return axios.post(`${API_URL}/customer/articles/qrcode/find`, postData, getApiHeaders())
}
const fetchProductLog = postData => axios.post(
    `${API_URL}/customer/articles/product/log/overview`, postData, getApiHeaders()
);

// POST Depot
const fetchDepotCases = postData => (
    axios.post(`${API_URL}/customer/depot/cases`, postData, getApiHeaders())
)

// GET products
const getProductDetails = productId => axios.get(
    `${API_URL}/customer/articles/details/` + productId, getApiHeaders()
);
const fetchProductBarcode = productId => axios.get(
    `${API_URL}/customer/articles/barcode/` + productId, getApiHeaders()
);
const getArticleCustomfields = articleId => axios.get(
    `${API_URL}/customer/articles/customfields/` + articleId, getApiHeaders()
);

// GET Depot
const fetchDepotDetails = depotCaseId => axios.get(
    `${API_URL}/customer/depot/details/` + depotCaseId, getApiHeaders()
);


// PUT (create / write rechten)
const actionProductRepair = postData => (
    axios.put(`${API_URL}/customer/articles/product/request/repair`, postData, getApiHeaders())
);

const actionProductAdd = postData => (
    axios.put(`${API_URL}/customer/articles/product/add`, postData, getApiHeaders())
);
const actionProductProcessClientrepair = postData => (
    axios.put(`${API_URL}/customer/articles/product/client/repair`, postData, getApiHeaders())
)
const actionProductProcessReservation = postData => (
    axios.put(`${API_URL}/customer/depot/reservation`, postData, getApiHeaders())
)
// deze kan put of patch zijn, dan kiezen wel put
const actionProductRelocate = postData => (
    axios.put(`${API_URL}/customer/articles/product/edit/location`, postData, getApiHeaders())
);
// DEPOT PUT
const actionProductDepotIn = postData => (
    axios.put(`${API_URL}/customer/articles/product/request/depot-in`, postData, getApiHeaders())
);
const actionProductDepotOut = postData => (
    axios.put(`${API_URL}/customer/depot/request/depot-out`, postData, getApiHeaders())
);
// PATCH
const actionProductClient = postData => (
    axios.put(`${API_URL}/customer/articles/product/edit/client`, postData, getApiHeaders())
);
const actionProductEdit = postData => (
    axios.put(`${API_URL}/customer/articles/product/edit/data`, postData, getApiHeaders())
);
const actionQrCodeAdd = postData => {
    axios.put(`${API_URL}/customer/articles/product/edit/qrcode`, postData, getApiHeaders())
}


// ////////// FUNCTIONS /////////// //
const getProductBarcode = (productId, setBarCode) => {
    let product = JSON.parse(localStorage.getItem("product-" + productId));
    if (product !== undefined && product !== null && product.barcode !== undefined) {
        setBarCode(product.barcode);
    }
    else {
        fetchProductBarcode(productId).then((res) => {
            const response = res.data;
            if (response.status === 'success') {

                if (product === undefined || product === null) {
                    product = {};
                }
                let barcode = response.barcode ?? null;
                product.barcode = barcode;
                localStorage.setItem("product-" + productId, JSON.stringify(product))
                setBarCode(barcode);
            }
        });
    }
}
const getProductStatus= (productId) => {

    let product = JSON.parse(localStorage.getItem("product-" + productId));

    if (product === undefined || product === null || product.status === undefined) {
        getProductDetails(productId).then((res) => {
            const response = res.data;
            if (response.status === 'success') {

                product = response.details;
                localStorage.setItem("product-" + productId, JSON.stringify(product));
            }
        });
    }

    if (product !== undefined && product !== null && product.status !== undefined) {
        if(product.enableReservation === 1 || product.enableReservation === "1") {
            return "RESERVED";
        }
        return(product.status);
    }
}

export{
    actionQrCodeAdd,
    fetchProducts,
    fetchProductRepairs,
    fetchProductCleanings,
    fetchProductInspections,
    actionProductRelocate,
    actionProductRepair,
    actionProductDepotIn,
    actionProductDepotOut,
    actionProductClient,
    actionProductProcessClientrepair,
    actionProductProcessReservation,
    actionProductEdit,
    actionProductAdd,
    findQrCode,
    getProductDetails,
    fetchProductBarcode,
    getArticleCustomfields,
    fetchDepotCases,
    fetchDepotDetails,
    fetchProductLog,
    
    // functions
    getProductBarcode,
    getProductStatus
}


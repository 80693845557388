
/**
 * voor renderen van formulier: @see FormRender
 */

const validateData = (fields, data, isSubmitted) => {

    let errors = {};
    let status = null;
    Object.keys(fields).forEach((name) => {

        let result = validate(data[name], fields[name].rules, isSubmitted);

        if (result.status !== 'success') {
            errors[name] = result.message;
            status = 'error';
        }
        else if (status === null) {
            status = 'success'; // only set to success if there is something validated
        }
    });

    return {
        status: status,
        errors: errors
    }
}

/**
 * @see AppArticlesProductActionsEdit (daar werkt het!) 
 * soms verversen elementen niet omdat een aanpassing in een object niet als een aanpassing wordt gezien
 * let ook op dat voor de required fields de validate nog een keer moet worden uitgevoerd met isSubmitted = true
 * want anders worden de required fields overgeslagen
 */
const validate = (dataValue, rules, isSubmitted) => {

    let validationResult = { status: "success" };
    if (rules === undefined || rules === null) {
        return validationResult;
    }

    //  no value
    if (dataValue === undefined || dataValue===null || dataValue.length === 0) {
        dataValue = null;
    }

    rules.forEach((ruleName) => {

        switch (ruleName) {
            case 'required':
                if ((dataValue === null) && isSubmitted === true) {
                    validationResult.message = "Verplicht veld";
                    validationResult.status = "error";
                }
                break;
            case 'numeric':
                if (dataValue !== null && isNaN(dataValue) === true) {
                    validationResult.message = "Ongeldige numerieke waarde";
                    validationResult.status = "error";
                }
                break;
            case 'email':
                if (dataValue !== null && isSubmitted === true && validateEmail(dataValue) === false) {
                    validationResult.message = "Ongeldig e-mail adres";
                    validationResult.status = "error";
                }
                break;
            default:
                console.log('no rule for ' + ruleName);
                break;
        }
    });

    if (validationResult.status !== "success") {
        if (validationResult.message === undefined || validationResult.message === null) {
            validationResult.message = "Ongeldige invoer";
        }
    }

    return validationResult;
}

const validateEmail = (value) => {

    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (value.match(validRegex)) {
        return true;

    } else {
        return false;
    }
}

const returnValues = (values, key, value, identifier = null) => {
    let newValues = values ?? {};

    if (identifier !== null) {
        if (newValues[identifier] === undefined) {
            newValues[identifier] = {}
        }
        newValues[identifier][key] = value;
    }
    else {
        newValues[key] = value;
    }
    return newValues;
}

const removeValue = (values, key, identifier = null) => {
    let newValues = values;

    if (!values) {
        return newValues;
    }

    if (identifier !== null) {
        if (newValues[identifier] !== undefined && newValues[identifier][key] !== undefined) {
            delete newValues[identifier][key];
        }
    }
    else if (newValues[key] !== undefined) {
        delete newValues[key];
    }
    return newValues;
}

// @todo: deze twee functies re-factoren ... @see customfields ook
const convertOptionsList = (options) => {

    if (Array.isArray(options)) {
        return options;
    }

    let list = []

    Object.keys(options).map((option) =>
        list.push({ value: option, label: options[option] })
    )

    return list;
}

const getOptionsValue = (value, options) => {
    let list = convertOptionsList(options)
    let result;

    Object.keys(list).forEach((option) => {
        if (list[option]['value'] === value) {
            result = list[option];
        }
    });

    return result;
}

export {
    validate,
    validateData,
    returnValues,
    removeValue,
    convertOptionsList,
    getOptionsValue
}
import React, { useState, useEffect } from "react";
import { ValidateResponse} from "../../../helpers/global";
import { getPermissionLevel } from "../../../helpers/auth";
import { getProductDetails, actionProductEdit,  getArticleCustomfields } from "../../../helpers/articles";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../../../components/Form/Fields";
import { validate } from "../../../../components/Form/Formfunctions";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";


export function AppArticlesProductActionsEdit({ productId, parents }) {

    const navigate = useNavigate();
    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(); // does NOT render form
    const [errorMsg, setErrorMsg] = useState(); // showw error AND renders form
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({});
    const [customFields, setCustomFields] = useState();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if(state !== null && state !== undefined){
            getArticleCustomfields(state.productTemplateId).then((res) => {

                const response = ValidateResponse(res.data);
                if (response.status === 'success') {

                    setCustomFields(response.response);
                }
                else {
                    setError(response.message);
                }
            })
        }
    }, [state])

    useEffect(() => {

        getProductDetails(productId).then((res) => {
            const response = ValidateResponse(res.data, 'details');
            if (response.status === 'success') {
                setState(response.details);

                let values = {
                    productId: productId,
                    productBrand: response.details.productBrand,
                    registrationDate: response.details.registrationDate,
                    productType: response.details.productType,
                    dimensions: response.details.dimensions,
                    frameNumber: response.details.frameNumber,
                    serialNumber: response.details.serialNumber,
                    remarks: response.details.remarks,
                }

                Object.keys(response.details.customFields).forEach((statekey) => {
                        let id = response.details.customFields[statekey].id;
                        values = {...values, [id]:response.details.customFields[statekey].valueRaw};
                });

                setValues(values);
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }
        })
    }, [productId])

    const handleInput = (name, value, identifier) => {

        let newValues = values;
        if (newValues.customFields === undefined) {
            newValues.customFields = {};
        }
        // add customfield
        if (customFields !== undefined && customFields[name] !== undefined) {
            newValues.customFields[name] = value;
        }
        else {
            newValues[name] = value;
        }

        if (identifier !== undefined && identifier !== null) {
            if (newValues[identifier] === undefined) {
                newValues[identifier] = {}
            }
            newValues[identifier][name] = value;
        }
        else {
            newValues[name] = value;
        }

        setValues(newValues);
        return;
    }

    const handleFormErrors = (fieldKey, errorMessage, fieldIdentifier) => {

        let errorList = formErrors;
        if(fieldIdentifier === null || fieldIdentifier === undefined) {
            errorList[fieldKey] = errorMessage;
        }
        else {
            errorList[fieldIdentifier][fieldKey] = errorMessage;
        }
        setFormErrors(errorList);
        setRefresh(refresh === false);
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    const fields = {
        'productBrand': { type: 'text', label: "Merk", value:values.productBrand},
        'productType': { type: 'text', label: "Type" },
        'dimensions': { type: 'text', label: "Afmetingen" },
        'registrationDate': {
            type: "date",
            label: "Bouwjaar",
            options: { min: "-125", max: null },
        },
        'remarks': { type: 'textarea', label: "Opmerkingen"},
        'serialNumber': { type: 'text', label: "Serienummer", rules:["required"]},
        'frameNumber': { type: 'text', label: "Framenummer" }
    }

    // Add custom fields
    if (customFields !== undefined) {
        Object.keys(customFields).forEach((key) => {
            fields[key] = {
                type: customFields[key].type,
                label: customFields[key].properties.label,
                options: customFields[key].properties.options ?? null,
            }
        });
    }

    if (getPermissionLevel('/management') > 0) {
        fields['purchaseValue'] =  { type: 'money', label: "Inkoopwaarde", rules:["numeric"]}
    }

    const sendValues = () => {

        let validated = true;
        Object.keys(fields).forEach((fieldkey) => {
            let result = validate(values[fieldkey], fields[fieldkey].rules, true);
            if(result.status === 'error') {
                validated = false;
                let errorList = formErrors;
                errorList[fieldkey] = result.message;
                setFormErrors(errorList);
            }
        });

        if (validated === false) {
            setErrorMsg('Formulier kan niet verstuurd zijn omdat er ongeldige waarden zijn ingevoerd');
            return;
        }

        let postData = {productId: productId, customFields: values.customFields};

        Object.keys(values).forEach((postkey) => {
            Object.keys(fields).forEach((fieldkey) => {
                if(fieldkey === postkey){
                    postData = {...postData, [postkey]: values[postkey]}
                }
            });
        });


        actionProductEdit(postData).then((res) => {

            const response = ValidateResponse(res.data, 'status');

            if (response.status === 'success') {
                navigate("/" + parents.replace("actions", "details/") + productId, {
                    replace: true,
                    state: { message: response.message, type:response.status}
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    const content = () => {
        return <>
            <Message type="error" message={errorMsg} />

            <h3 className="box-header">Artikelgegevens wijzigen</h3>
            <FormFields
                formFields={fields}
                setValues={handleInput}
                setErrors={handleFormErrors}
                formValues={values}
                formErrors={formErrors}
            />
            <FormInputSubmit
                handleSubmit={sendValues}
                cancelUri={"/" + parents + "/" + productId}
            />
        </>
    }

    return (
        <ContentWrapper
            type="outline-box"
            content={content()}
            className="outline-box--form"
        />
    );
}
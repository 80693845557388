import React, { useState } from "react";
import { FormFields } from "./Form/Fields";

/**
 * Filters are set in the app component in form-format:
 *  let filterFields = {
        'fieldName': { type: 'text', label: "Field label" },
        'selectFieldName': { type: 'select', label: "Select box", options:[] },
        ...
  ] 
 *
 * The filter renderer is located in the Header component, so the filters have to be passed from the app component, back to the routing index, into the header component
 * use setFilterFields in component params to send filers up to router index
 * 
 * in the app component, to detect filtervalues:
 *  const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)));
    const [filterString, setFilterString] = useState();

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1);
    }
 * Do not forget to set the filters AND filterString (!) in the callback dependencies of the fetch function, 
 * so page will reload (changes in objects are not always recognized, so will not re-fetch data):
    fetchSomething(callData).then((res) => {
        ...
        })
    }, [filters, filterString, ...];
 *
 * In the router index:
        const [ filterFields, setFilterFields ] = useState([]);
    const [ filterValues, setFilterValues ] = useState(JSON.parse(localStorage.getItem("filters" + identifier)) ?? {});
    const [ refresh, setRefresh ] = useState(false);

    const resetFilterValues = (values) => {
        setFilterValues(values);
        setRefresh(refresh === false);
    }
 */
export function Filters({ setFilterValues, fields = {}, identifier}) {

    if (identifier === undefined) {
        identifier = 'filters';
    }

    // first collect all values locally, send them "up" on submit ...(why not directly?)
    const prefill = localStorage.getItem("filters" + identifier) ?? "{}";
    const [values, setValues] = useState(JSON.parse(prefill));
    const [toggle, setToggle] = useState(false);
    const [errors, setErrors] = useState();
    const [refresh, setRefresh] = useState(false);

    let hasFields = fields !== undefined && Object.keys(fields).length !== 0;
    let hasValues = values !== undefined && Object.keys(values).length !== 0;

    if (hasFields === false) {
        return;
    }

    // Submit & set in localstorage
    const handleFilters = () => {
        if (Object.keys(values).length > 0) {
            localStorage.setItem("filters" + identifier, JSON.stringify(values))
        } else {
            localStorage.removeItem("filters" + identifier)
        }
        setFilterValues(values);
        setToggle(false);
    }

    const resetFilters = () => {
        setValues({});
        localStorage.removeItem("filters" + identifier);
        setFilterValues({});
        window.location.reload();
    }

    const handleInput = (key, value) => {
        let newValues = values;
        newValues[key] = value;
        setValues(newValues); // waarom refresht die soms niet? (niet op location element, wel op andere..)
        setRefresh(refresh !== true);
    }

    return (
        <>
            <div className="col-auto buttons page-filters">
                {hasValues === true &&
                    <button
                        type={"button"}
                        onClick={() => resetFilters()}
                        className="button button--outline button--icon--filters"
                    >
                        Reset Filters
                    </button>
                }
                <button
                    type={"button"}
                    onClick={() => setToggle(!toggle)}
                    className="button button--outline button--icon button--icon--filters">
                    Filter
                </button>
                <div className="overlays">
                    <div
                        className={"overlay overlay--primary overlay--filters-overlay" + (toggle === true ? " overlay--visible" : "")}
                        id="overlay-filters">
                        <div className="overlay__inner">
                            <div className="overlay__top">
                                <div className="topbar">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="topbar__inner">
                                                    <div
                                                        className="topbar__title topbar__title--icon topbar__title--filters">Filters
                                                    </div>
                                                    <div className="topbar__right">
                                                        <div className="topbar__icons">
                                                            <span className="close-link"
                                                                onClick={() => setToggle(false)}>Sluiten</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overlay__center">
                                <div className="overlay__content text-component">
                                    <FormFields
                                        formFields={fields}
                                        setValues={handleInput}
                                        setErrors={setErrors}
                                        formValues={values}
                                        formErrors={errors}
                                        identifier={identifier}
                                    />
                                </div>
                            </div>
                            <div className="overlay__footer">
                                {hasValues === true &&
                                    <div style={{paddingBottom:"22px"}}>
                                        <button type="button" className="button button--primary" style={{marginLeft:"0px"}}
                                            onClick={() => { handleFilters() }}>Toepassen
                                        </button>
                                        <button
                                            type={"button"}
                                            className="button button--outline"  style={{marginLeft:"0px"}}
                                            onClick={() => { resetFilters() }}
                                        >
                                            Reset Filters
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
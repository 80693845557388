import React, { useState} from "react";
import { useParams } from "react-router-dom";
import { PageTopbar } from "../../components/Page/Topbar";
import { PageHeader } from "../../components/Page/Header";
import { AppRequestDetails } from "../../app/Requests/Details";

export function RoutesRequestsDetails({ parents, current }) {


    const { customerRequestId } = useParams();
    const [title, setTitle] = useState(null);

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            <PageHeader
                title={title}
                parents={parents}
                current={current}
            />

            <AppRequestDetails
                customerRequestId={customerRequestId}
                setTitle={setTitle}
            />
        </div>
    );
}
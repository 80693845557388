import React from "react";
import { validate } from "./Formfunctions";
import { FormRender } from "./Render";
import { FormInputSerialOptions } from "./Input/Serialoptions";
import { FormInputLocation } from "./Input/Location";
import { FormInputWarehouse } from "./Input/Warehouse";
import { FormInputInspectionType} from "./Input/Inspectiontype";
import { FormInputContacts} from "./Input/Contacts";

/**
 * @documentation Form element
 * 
 * * Example formFields: {
    fieldName: {
        type:"text",
        label:"Dit is een tekstveld",
        value:"Defaultvalue"
        },
    otherField: {
        type:"select",
        label:"Dit is een selectbox",
        value:"Defaultvalue",
        options: {a:"A", b:"B"}
        }
    dateField: {
        type:"date",
        label:"Dit is een datumveld",
        options: {min:"-1", max:"+1"}
        }
    }
  * 
  * * Example function to handle setValues in parent element
  * * import helpers -> returnValues, use constant [values, setValues]
  * 
    const setInputValues = (key, value, identifier = null) => {
        setValues(returnValues(values, key, value, identifier));
    }
  * 
  * * Example function to handle setErrors in parent element
  * * import helpers -> returnValues, use constant [errors, setErrors]
  * 
    const setInputErrors = (key, value, identifier = null) => {
        setErrors(returnValues(errors, key, value, identifier));
    }
 * 
 */

export function FormFields({
    formFields,         // * required object
    setValues,          // * required function: passes user input to parent element
    setErrors,          // * required function: passes errors to parent element
    formValues = {},    // optional object: get default values from parent
    formErrors = {},    // optional object: get errors from parent
    identifier = null,  // optional string: use an extra identifier for the field (if the fieldname exists more than one time)
    isSubmitted = false, // optional boolean: if form is submitted, required fields will be 
    wrapper = 'default'
}) {

    let errors = formErrors; // setErrors will pass the error to the parent and parent will pass them here bu formErrors

    const handleValues = (dataName, dataValue, identifier = null) => {

        formValues[dataName] = dataValue;

        if (formFields[dataName] !== undefined) {
            let validation = validate(dataValue, formFields[dataName].rules, false);
            let error = validation.status === 'error' ? validation.message : null;
            
            setErrors(dataName, error, identifier);
        }

        setValues(dataName, dataValue, identifier);
    }

    // The error can be set in the formErrors (required field or here)
    const getError = (fieldName) => {

        let errorMessage = null;

        if (errors !== undefined && errors[fieldName] !== undefined) {
            errorMessage = errors[fieldName];
        }
        else if (isSubmitted === true
            && formValues[fieldName] === undefined
            && formFields[fieldName].rules !== undefined
            && formFields[fieldName].rules.includes('required')) {

            errorMessage = "Verplicht veld";
        }

        if (errorMessage !== null) {
            return errorMessage;
        }
        return null;
    }

    const getFieldsetLabel = (options, elementName, fieldOptionKey) => {
        return options[elementName].label;
    }

    return (
        <div className={"form__" + wrapper}>
            {Object.keys(formFields).map((fieldName) =>
                <div className="form__row" key={identifier + fieldName}>

                    {formFields[fieldName].type !== 'serialOptions'
                        && formFields[fieldName].type !== 'fieldset'
                        && formFields[fieldName].type !== 'location'
                        &&
                        <FormRender
                            setValues={handleValues}
                            fieldType={formFields[fieldName].type}
                            fieldName={fieldName}
                            fieldValue={formValues[fieldName] ?? formFields[fieldName].value}
                            fieldLabel={formFields[fieldName].label}
                            fieldError={getError(fieldName)}
                            placeholder={formFields[fieldName].placeholder ?? null}
                            identifier={identifier}
                            fieldOptions={formFields[fieldName].options ?? {}}
                            info={formFields[fieldName].info}
                        />
                    }

                    {formFields[fieldName].type === 'location' &&
                        <FormInputLocation
                            setValues={handleValues}
                            fieldValue={formValues}
                            fieldLabel={formFields[fieldName].label}
                            fields={formFields[fieldName].options}
                            identifier={identifier}
                        />
                    }

                    {formFields[fieldName].type === 'warehouse' &&
                        <FormInputWarehouse
                            setValues={handleValues}
                            fieldValue={formValues}
                            fieldLabel={formFields[fieldName].label}
                            fields={formFields[fieldName].options}
                            identifier={identifier}
                        />
                    }

                    {formFields[fieldName].type === 'inspectionType' &&
                        <FormInputInspectionType
                            setValues={handleValues}
                            fieldValue={formValues}
                            fieldLabel={formFields[fieldName].label}
                            fields={formFields[fieldName].options}
                            identifier={identifier}
                        />
                    }
                    {formFields[fieldName].type === 'contacts' &&
                        <FormInputContacts
                            setValues={handleValues}
                            fieldValue={formValues}
                            fieldLabel={formFields[fieldName].label}
                            fields={formFields[fieldName].options}
                            identifier={identifier}
                        />
                    }
                    {
                        formFields[fieldName].type === 'serialOptions' &&
                        <FormInputSerialOptions
                            setValues={handleValues}
                            fieldName={fieldName}
                            options={formFields[fieldName].options ?? {}}
                            defaultValue={formValues[fieldName]}
                            isSubmitted={isSubmitted}
                            identifier={identifier}
                        // required={formFields[fieldName].rules !== undefined && formFields[fieldName].rules.includes('required') ? true: false}
                        />
                    }

                    {
                        formFields[fieldName].type === 'fieldset' &&
                        <div className="form-fieldset">
                            {Object.keys(formFields[fieldName].options).map((elementName, fieldOptionKey) =>
                                <div
                                    className={"fieldset-element " + (formFields[fieldName].options[elementName].className ?? "")}
                                    key={(identifier ?? "fieldset") + fieldName + elementName}
                                >

                                    <FormRender
                                        setValues={setValues}
                                        fieldType={formFields[fieldName].options[elementName].type}
                                        fieldName={elementName}
                                        fieldValue={formValues[elementName] ?? formFields[fieldName].options[elementName].value}
                                        fieldLabel={getFieldsetLabel(formFields[fieldName].options, elementName, fieldOptionKey)} //formFields[fieldName].options[elementName].label}
                                        fieldError={getError(elementName)}
                                        placeholder={formFields[fieldName].options[elementName].placeholder ?? null}
                                        identifier={identifier}
                                        fieldOptions={formFields[fieldName].options[elementName].options ?? {}}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </div >
            )
            }
        </div >
    )
}